













































































































































































import { Component } from "vue-property-decorator";
import { APIError, BadRequest, GenericError, NotFoundError } from "@/services/error_service";
import { mixins } from "vue-class-component";
import Configurator from "../designer/Configurator.vue";
import { Collection, ProductLineName } from "@/models/products/collection";
import ConfigFileService from "@/services/config_file_service";
import { UmbrellaType } from "@/models/products/umbrella";
import { QuickshipConfigFile } from "@/models/configurator/config_file";
import { GetFinishImage } from "@/mixins/helpers";
import { FinishDisplayName, FinishHandle } from "@/models/products/finish";

@Component
export default class QuickShipUmbrellas extends mixins(
  Configurator,
  GetFinishImage
) {
  protected show = false;
  protected umbrellas: Collection[] = [];
  protected configFileService = new ConfigFileService();
  protected UmbrellaType = UmbrellaType;
  protected quickShipFiles: QuickshipConfigFile[] = [];
  protected handle = this.$route.params.handle.replace(/-/g, "_");
  protected ProductLineName = ProductLineName;

  async created() {
    this.quickShipFiles = await this.fetchQuickShip();
  }

  protected async fetchQuickShip(): Promise<QuickshipConfigFile[]> {
    let files: QuickshipConfigFile[] = [];
    try {
      const res = await this.configFileService.getQuickshipFiles();
      files = res;
    } catch (err) {
      if (err instanceof NotFoundError) {
        files = [];
      } else if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return files;
  }

  protected get filteredQuickShip(): QuickshipConfigFile[] {
    return this.quickShipFiles.filter(
      (file) => file.layout.productline?.handle === this.handle
    );
  }

  protected async select(index: number): Promise<void> {
    const loader = this.$loading.show({ opacity: 1 });
    const selected = { ...this.filteredQuickShip[index] };
    delete selected.fabric;
    delete selected.umbrella;
    try {
      await this.transformConfigFileAndSaveToStore(selected);
      loader.hide();
      this.$router.push({
        name: "RequestQuote",
        query: { quickship: "true" },
        params: {
          img1: selected.image!,
        },
      });
    } catch (err) {
      if (err instanceof NotFoundError) {
      APIError.redirect(
        "There was an error loading the umbrella configuration. The selected umbrella and/or its parts or fabric may no longer be available. If you believe this is an error, please contact Frankford Umbrellas directly for more information at info@frankfordumbrellas.com."
      );
      } else {
        this.$router.push('/error')
      }
    }
  }

  public makeTitleCase(handle: string) {
    const words = handle.split(" ");
    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  }

  public formatKeyName(name: string): string {
    return this.makeTitleCase(name);
  }

  protected get productLine(): string {
    const handle = this.$route.params.handle.replace(/-/g, " ");
    return this.makeTitleCase(handle);
  }

  protected getFinishName(stringHandle: FinishHandle): FinishDisplayName {
    const handle = stringHandle as FinishHandle;
    let name = "" as FinishDisplayName;
    switch (handle) {
      case FinishHandle.MS:
        name = FinishDisplayName.MS;
        break;
      case FinishHandle.WG:
        name = FinishDisplayName.WG;
        break;
      case FinishHandle.BK:
        name = FinishDisplayName.BK;
        break;
      case FinishHandle.WH:
        name = FinishDisplayName.WH;
        break;
      case FinishHandle.BZ:
        name = FinishDisplayName.BZ;
        break;
      case FinishHandle.SR:
        name = FinishDisplayName.SR;
        break;
      case FinishHandle.HW:
        name = FinishDisplayName.HW;
        break;
      case FinishHandle.MSA:
        name = FinishDisplayName.MSA;
        break;
      case FinishHandle.SM:
        name = FinishDisplayName.SM;
        break;
      case FinishHandle.SS:
        name = FinishDisplayName.SS;
        break;
      case FinishHandle.GS:
        name = FinishDisplayName.GS;
        break;
      case FinishHandle.ASH:
        name = FinishDisplayName.ASH;
        break;
      case FinishHandle.GBK:
        name = FinishDisplayName.GBK;
        break;
    }
    return name;
  }
  protected formatTitle(title: string, part: string): string {
    let result = "";
    if (title.indexOf(" ")) {
      if (part == "a") {
        result = title.substr(0, title.indexOf(" "));
      } else {
        result = title.substr(title.indexOf(" ") + 1);
      }
    } else {
      return title;
    }
    return result;
  }

  get cdnUrl(): string {
    return process.env.VUE_APP_CDN_URL + "/fabric_swatches/";
  }
}
