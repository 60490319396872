import { Component, Vue } from "vue-property-decorator";
import { FinishHandle } from "@/models/products/finish";

@Component
export class GetFinishImage extends Vue {
  protected getFinishImg(handle: string | FinishHandle): string {
    let img = "";
    for (const value in FinishHandle) {
      if (handle === "msa" || handle === "ss" || handle === "gs") {
        img = require(`../assets/images/finishes/ms.jpg`);
      } else if (FinishHandle[value] === handle) {
        img = require(`../assets/images/finishes/${handle}.jpg`);
      }
    }
    return img;
  }
}
